const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://zdrive.dvgeo.app' : 'http://192.168.100.106:3065',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://zdrive.dvgeo.app' : 'http://192.168.100.106:3065',
    api: 'api/',
    apiSocket: 'zdrive::1.2',
    nameDir: 'zdrive',
    package: 'app.dvgeo.zdrive.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBMNPJ66GWK3M43bbYN5-WLBPUakb2whS4',
    appName: 'ZDrive',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FF0012',
    colorDark: '#078F0A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.zdrive.passenger',
    playStoreDriverId: 'app.dvgeo.zdrive.driver',
    appStorePassengerId: '6473797646',
    appStoreDriverId: '6473797749',
    email: "igmar.dreams@gmail.com",
};
export default config;
